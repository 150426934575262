import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2d7a4e72 = () => interopDefault(import('../pages/theme-1/favorite-games/index.vue' /* webpackChunkName: "pages/theme-1/favorite-games/index" */))
const _11cfe641 = () => interopDefault(import('../pages/theme-1/forgot-password.vue' /* webpackChunkName: "pages/theme-1/forgot-password" */))
const _303241b5 = () => interopDefault(import('../pages/theme-1/my-bonus/index.vue' /* webpackChunkName: "pages/theme-1/my-bonus/index" */))
const _5e52b392 = () => interopDefault(import('../pages/theme-1/no-support.vue' /* webpackChunkName: "pages/theme-1/no-support" */))
const _530f91c4 = () => interopDefault(import('../pages/theme-1/not-found.vue' /* webpackChunkName: "pages/theme-1/not-found" */))
const _2beb7f27 = () => interopDefault(import('../pages/theme-1/play-game.vue' /* webpackChunkName: "pages/theme-1/play-game" */))
const _7e294022 = () => interopDefault(import('../pages/theme-1/promotion/index.vue' /* webpackChunkName: "pages/theme-1/promotion/index" */))
const _b0bc817c = () => interopDefault(import('../pages/theme-1/refer-friend/index.vue' /* webpackChunkName: "pages/theme-1/refer-friend/index" */))
const _9752bc58 = () => interopDefault(import('../pages/theme-1/referral/index.vue' /* webpackChunkName: "pages/theme-1/referral/index" */))
const _ebfc27d2 = () => interopDefault(import('../pages/theme-1/register.vue' /* webpackChunkName: "pages/theme-1/register" */))
const _285240e0 = () => interopDefault(import('../pages/theme-1/reset-password/index.vue' /* webpackChunkName: "pages/theme-1/reset-password/index" */))
const _da1e8dc2 = () => interopDefault(import('../pages/theme-1/history/transaction.vue' /* webpackChunkName: "pages/theme-1/history/transaction" */))
const _223cf640 = () => interopDefault(import('../pages/theme-1/player/banks/index.vue' /* webpackChunkName: "pages/theme-1/player/banks/index" */))
const _e26eafbe = () => interopDefault(import('../pages/theme-1/player/bet-history/index.vue' /* webpackChunkName: "pages/theme-1/player/bet-history/index" */))
const _4c0cd182 = () => interopDefault(import('../pages/theme-1/player/deposits/index.vue' /* webpackChunkName: "pages/theme-1/player/deposits/index" */))
const _0f4a1c79 = () => interopDefault(import('../pages/theme-1/player/messages/index.vue' /* webpackChunkName: "pages/theme-1/player/messages/index" */))
const _d6216622 = () => interopDefault(import('../pages/theme-1/player/profile.vue' /* webpackChunkName: "pages/theme-1/player/profile" */))
const _5b9c8e60 = () => interopDefault(import('../pages/theme-1/player/wallets.vue' /* webpackChunkName: "pages/theme-1/player/wallets" */))
const _671fe0b2 = () => interopDefault(import('../pages/theme-1/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-1/player/withdrawals/index" */))
const _12212e53 = () => interopDefault(import('../pages/theme-1/providers/games/index.vue' /* webpackChunkName: "pages/theme-1/providers/games/index" */))
const _00767e1e = () => interopDefault(import('../pages/theme-1/player/deposits/apply.vue' /* webpackChunkName: "pages/theme-1/player/deposits/apply" */))
const _fe4c877a = () => interopDefault(import('../pages/theme-1/player/withdrawals/apply.vue' /* webpackChunkName: "pages/theme-1/player/withdrawals/apply" */))
const _c1d868e4 = () => interopDefault(import('../pages/theme-1/index.vue' /* webpackChunkName: "pages/theme-1/index" */))
const _c286ad0a = () => interopDefault(import('../pages/theme-1/providers/games/_id.vue' /* webpackChunkName: "pages/theme-1/providers/games/_id" */))
const _1304d173 = () => interopDefault(import('../pages/theme-1/Information/_id.vue' /* webpackChunkName: "pages/theme-1/Information/_id" */))
const _15ece30a = () => interopDefault(import('../pages/theme-1/promotion/_id.vue' /* webpackChunkName: "pages/theme-1/promotion/_id" */))
const _632c3009 = () => interopDefault(import('../pages/theme-1/providers/_id.vue' /* webpackChunkName: "pages/theme-1/providers/_id" */))
const _36a98a80 = () => interopDefault(import('../pages/theme-1/_type/_id.vue' /* webpackChunkName: "pages/theme-1/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/favorite-games",
    component: _2d7a4e72,
    name: "favorite-games"
  }, {
    path: "/forgot-password",
    component: _11cfe641,
    name: "forgot-password"
  }, {
    path: "/my-bonus",
    component: _303241b5,
    name: "my-bonus"
  }, {
    path: "/no-support",
    component: _5e52b392,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _530f91c4,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _2beb7f27,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _7e294022,
    name: "promotion"
  }, {
    path: "/refer-friend",
    component: _b0bc817c,
    name: "refer-friend"
  }, {
    path: "/referral",
    component: _9752bc58,
    name: "referral"
  }, {
    path: "/register",
    component: _ebfc27d2,
    name: "register"
  }, {
    path: "/reset-password",
    component: _285240e0,
    name: "reset-password"
  }, {
    path: "/history/transaction",
    component: _da1e8dc2,
    name: "history-transaction"
  }, {
    path: "/player/banks",
    component: _223cf640,
    name: "player-banks"
  }, {
    path: "/player/bet-history",
    component: _e26eafbe,
    name: "player-bet-history"
  }, {
    path: "/player/deposits",
    component: _4c0cd182,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _0f4a1c79,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _d6216622,
    name: "player-profile"
  }, {
    path: "/player/wallets",
    component: _5b9c8e60,
    name: "player-wallets"
  }, {
    path: "/player/withdrawals",
    component: _671fe0b2,
    name: "player-withdrawals"
  }, {
    path: "/providers/games",
    component: _12212e53,
    name: "providers-games"
  }, {
    path: "/player/deposits/apply",
    component: _00767e1e,
    name: "player-deposits-apply"
  }, {
    path: "/player/withdrawals/apply",
    component: _fe4c877a,
    name: "player-withdrawals-apply"
  }, {
    path: "/",
    component: _c1d868e4,
    name: "index"
  }, {
    path: "/providers/games/:id",
    component: _c286ad0a,
    name: "providers-games-id"
  }, {
    path: "/Information/:id?",
    component: _1304d173,
    name: "Information-id"
  }, {
    path: "/promotion/:id",
    component: _15ece30a,
    name: "promotion-id"
  }, {
    path: "/providers/:id?",
    component: _632c3009,
    name: "providers-id"
  }, {
    path: "/:type/:id?",
    component: _36a98a80,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
